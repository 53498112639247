<template>
    <div class="pcdiy-box" v-loading="loading">
        <div v-for="(item) in viewContent" :key="item.id">
            <div v-if="item.block_id == 1" v-html="item.content" ></div>
            <div v-if="item.block_id == 2 && item.goods && item.goods.length>0" class="list-wrap" :style="item.style">
                <div class="goods-info">
                    <div class="item"
                            v-for="(citem, cindex) in item.goods" :key="cindex"
                            @click="$router.pushToTab({ path: '/sku-' + citem.id+ '?source_type=0'})">
                        <div class="goods-wrap">
                            <div class="img-wrap"><img alt="商品图片" :src="$img(citem.image, { size: 'mid' })" @error="imageError(cindex)" /></div>
                            <h3>
                                <span class="p_tag_832" v-if="citem.tag_type == 1">832产品</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 1">国贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 2">省贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 3">市贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 4">县贫认定</span>
                                {{ citem.title }}</h3>
                            <p class="price">
                                <label>￥</label>{{ citem.price }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="nice-goods" v-if="item.block_id == 3">-->
<!--                <div class="nice-goods__logo">-->

<!--                </div>-->
<!--                <div class="nice-goods__recommends">-->
<!--                    <vue-seamless-scroll :data="supplierList" :class-option="optionLeft" class="seamless-warp2">-->
<!--                        <ul class="supplier-ul" :style="{width:widthData+'px'}">-->
<!--                            <li class="supplier-item" v-for="(citem, cindex) in supplierList" :key="cindex"-->
<!--                                @click="$router.pushToTab({ path: '/sku-' + citem.id+ '?source_type=0'})">-->
<!--                                <div class="goods-wrap">-->
<!--                                    <div class="img-wrap"><img alt="商品图片" :src="$img(citem.image, { size: 'mid' })" @error="imageError(cindex)" /></div>-->
<!--                                    <h3>-->
<!--                                        <span class="p_tag_832" v-if="citem.tag_type == 1">832产品</span>-->
<!--                                        <span class="p_tag_832" v-if="citem.tag_level == 1">国贫认定</span>-->
<!--                                        <span class="p_tag_832" v-if="citem.tag_level == 2">省贫认定</span>-->
<!--                                        <span class="p_tag_832" v-if="citem.tag_level == 3">市贫认定</span>-->
<!--                                        <span class="p_tag_832" v-if="citem.tag_level == 4">县贫认定</span>-->
<!--                                        {{ citem.title }}</h3>-->
<!--                                    <p class="price">-->
<!--                                        <label>￥</label>{{ citem.price }}-->
<!--                                    </p>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </vue-seamless-scroll>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import {supplierList} from "@/api/goods/goods";
import { getViewByPcdiy } from "@/api/goods/goods"
export default {
    name: "list",
    components: {

    },
    computed: {
        widthData() { return 200 * Number(this.supplierList.length); },
        optionLeft() {
            return {
                step: 0.3, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        },
    },
    data: () => {
        return {
            loading: true,
            supplierList:[
                {
                    "category_id": ",8,19,105,",
                    "id": 968,
                    "sales": 8,
                    "image": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20210724112315d4bc2eb360fb87a30bf64.png",
                    "title": "农科情虾田稻米5kg",
                    "shop": 11413,
                    "website": 12,
                    "is_own": 0,
                    "is_free": 1,
                    "des": "",
                    "images": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20210724112315d4bc2eb360fb87a30bf64.png,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_2021072411233073122b8d60fb87b231007.png,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20210724112341911302e060fb87bdefe49.png",
                    "tag_type": 1,
                    "shop_name": "湖南省佳油种植农民专业合作社",
                    "goods_spec": "",
                    "price": "72.00",
                    "market_price": "66.00",
                    "recommend": 0
                },
                {
                    "category_id": ",9,30,99,",
                    "id": 4145,
                    "sales": 7,
                    "image": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_2020101415534454c0e54d5f86ae881bf82.jpg",
                    "title": "苗老表凤凰腊肉纸袋精装400g",
                    "shop": 11438,
                    "website": 20,
                    "is_own": 0,
                    "is_free": 1,
                    "des": "苗老表凤凰腊肉纸袋精装400g\n",
                    "images": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_2020101415534454c0e54d5f86ae881bf82.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201014155349efdd2f965f86ae8d7d661.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_202010141553548714a7e55f86ae926538b.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201014155358c6a39e945f86ae96e861a.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_2020101415540440e0d5045f86ae9c0e4b6.jpg",
                    "tag_type": 1,
                    "shop_name": "凤凰县天源传统食品有限公司",
                    "goods_spec": "",
                    "price": "68.00",
                    "market_price": "68.00",
                    "recommend": 0
                },
                {
                    "category_id": ",9,30,99,",
                    "id": 4231,
                    "sales": 13,
                    "image": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201015095737135007e75f87ac9125cd4.jpg",
                    "title": "苗老表凤凰五花腊肉纸袋精装400g",
                    "shop": 11438,
                    "website": 20,
                    "is_own": 0,
                    "is_free": 1,
                    "des": "苗老表凤凰五花腊肉纸袋精装400g\n",
                    "images": "https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201015095737135007e75f87ac9125cd4.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_202010150957421c92e99f5f87ac962e1f9.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201015095748e506003d5f87ac9c38ba2.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_20201015095753c6a39e945f87aca10c55f.jpg,https://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/group-application/upload_pic/com_thumb_202010150957583cda74655f87aca6ad370.jpg",
                    "tag_type": 1,
                    "shop_name": "凤凰县天源传统食品有限公司",
                    "goods_spec": "",
                    "price": "68.00",
                    "market_price": "68.00",
                    "recommend": 0
                }
            ],
            viewContent:[],
        }
    },
    mounted() {
        let pcdiy_viewid = parseInt(this.$route.query.view_id)
        this.getViewContent(pcdiy_viewid)
    },
    methods: {
        getViewContent(pcdiy_viewid) {
            getViewByPcdiy({ id: pcdiy_viewid})
                .then(res => {
                    if(res.code == 0)
                    this.viewContent = res.data.view_info['view_floor']
                    // console.log('==',res)
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
    }
};
</script>
<style lang="scss" scoped>
    .pcdiy-box{
        width:1210px;
        min-height:400px;
        margin:0 auto;
        padding:0;
        text-align: center;
        background-color:#fff;
    }
    /*商品选择*/
    .list-wrap {
        width: 1210px;
        overflow: hidden;
        min-height: 500px;
        padding: 35px 0px;
        margin: 0 auto;
        .goods-info {
            width: 1160px;
            margin:0 auto;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 20%;
                margin: 0;
                cursor: pointer;
                padding:0;
                transition: all 0.2s linear;
                .goods-wrap{
                    margin: 0 10px 20px;
                    padding: 15px 0px;
                    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    min-height:250px;
                    background: #fff;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .img-wrap {
                        width: 180px;
                        height: 180px;
                        margin: 0px auto;
                        line-height: 180px;
                        /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                        /*border-radius: 16px;*/
                        overflow: hidden;
                        position:relative;
                        img {
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                        }
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        height: 44px;
                        text-align: left;
                        -webkit-box-orient: vertical;
                        margin: 8px 15px 3px;
                        .p_tag_832{
                            background-color: #e1251b;
                            border-radius: 2px;
                            color: #fff;
                            padding: 0 5px;
                            margin-right: 4px;
                            line-height: 16px;
                            font-size: 12px;
                            font-family: Helvetica Neue,Hiragino Sans GB,SimSun,serif;
                        }
                    }
                    .price {
                        margin:0 10px;
                        font-size: 18px;
                        line-height: 20px;
                        text-align: left;
                        color: $base-color;
                        font-weight: bold;
                        label{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    /*好物推荐*/
    .nice-goods {
        width:1210px;
        height: 280px;
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 190px;
        margin:20px 0px;
        .nice-goods__logo {
            position: absolute;
            left: 0;
            top: 0;
            width: 190px;
            height: 100%;
            background-color: #ffffc1;
        }
        .nice-goods__recommends {
            margin-left: 10px;
            height: 100%;
            position: relative;
            background-color: white;
            overflow: hidden;

            .seamless-warp2 {
                width: 1020px;
                height:280px;
                padding: 0;
                margin: 0;
                overflow: hidden;
                position: relative;
                ul {
                    padding: 0;
                    overflow: hidden;
                    height:280px;
                    position: relative;
                    float: left;

                    .supplier-item {
                        cursor: pointer;
                        width: 200px;
                        height: 260px;
                        background: #FFFFFF;
                        position: relative;
                        float: left;
                        padding-top: 13px;

                        .goods-wrap{
                            margin: 0 10px;
                            padding:0px;
                            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
                            border-radius: 4px;
                            min-height:250px;
                            background: #fff;

                            &:hover {
                                z-index: 2;
                                -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                                box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                                -webkit-transform: translate3d(0, -2px, 0);
                                transform: translate3d(0, -2px, 0);
                            }

                            .img-wrap {
                                width: 150px;
                                height: 150px;
                                margin: 0px auto;
                                line-height: 180px;
                                /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                                /*border-radius: 16px;*/
                                overflow: hidden;
                                position:relative;
                                img {
                                    border: none;
                                    position: absolute;
                                    margin-right: -75px;
                                    top:50%;
                                    left:50%;
                                    display: block;
                                    transform: translate(-50%,-50%);
                                }
                            }
                            h3 {
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                height: 44px;
                                text-align: left;
                                -webkit-box-orient: vertical;
                                margin: 8px 15px 3px;
                                .p_tag_832{
                                    background-color: #e1251b;
                                    border-radius: 2px;
                                    color: #fff;
                                    padding: 0 5px;
                                    margin-right: 4px;
                                    line-height: 16px;
                                    font-size: 12px;
                                    font-family: Helvetica Neue,Hiragino Sans GB,SimSun,serif;
                                }
                            }
                            .price {
                                margin:0 10px;
                                font-size: 18px;
                                line-height: 20px;
                                text-align: left;
                                color: $base-color;
                                font-weight: bold;
                                label{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
